import React, { useMemo, useState } from 'react';
import Modal from 'react-modal';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { env } from '@hrplatform/env';

import { AndroidContent } from './components/AndroidContent';
import { CannotLoginInstruction } from './components/CannotLoginInstruction';
import { CannotLoginIosInstruction } from './components/CannotLoginIosInstruction';
import { CertificateInstruction } from './components/CertificateInstruction';
import { DeviceDetector } from './components/DeviceDetector';
import { ErrorPage } from './components/ErrorPage';
import { IosContent } from './components/IosContent';
import { LoginContent } from './components/LoginContent';
import { MobileInstruction } from './components/MobileInstruction';
import { NoPilot } from './components/NoPilot';
import { PopupWindow } from './components/PopupWindow';
import { RedirectOrEnter } from './components/RedirectOrEnter';
import { WelcomePulse } from './components/WelcomePulse';
import { ModalContentType } from './modals';
import { AppContent, getThemedPopupStyles, themes } from './styles';
import { getDefaultTheme } from './themes';

// Нужно для a11y:
Modal.setAppElement('#root');

const getModalContentComponent = (contentType: ModalContentType | null) => {
  switch (contentType) {
    case ModalContentType.CERTIFICATE:
      return <CertificateInstruction />;
    case ModalContentType.CANNOT_LOGIN:
      return <CannotLoginInstruction />;
    case ModalContentType.CANNOT_LOGIN_IOS:
      return <CannotLoginIosInstruction />;
    case ModalContentType.MOBILE:
      return <MobileInstruction />;
    default:
      return null;
  }
};

// TODO: Разобраться, почему правило показывает ошибку
// eslint-disable-next-line react/display-name
export default () => {
  const { stand } = env();
  const defaultTheme = useMemo(() => getDefaultTheme(), []);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [modalContentType, setModalContentType] = useState<ModalContentType | null>(null);
  const [theme] = useState(defaultTheme);
  const content = {
    apple: (
      <WelcomePulse>
        <IosContent />
      </WelcomePulse>
    ),
    android: (
      <WelcomePulse>
        <AndroidContent />
      </WelcomePulse>
    ),
    other: (
      <>
        <WelcomePulse />
        <LoginContent
          onPopupTrigger={modalContentType => {
            setModalContentType(modalContentType);
            setIsOpen(true);
          }}
        />
      </>
    ),
  };

  // const toggleTheme = () => {
  //   if (theme === ColorThemes.LIGHT_MODE) {
  //     setTheme(ColorThemes.DARK_MODE);
  //     saveTheme(ColorThemes.DARK_MODE);
  //   } else {
  //     setTheme(ColorThemes.LIGHT_MODE);
  //     saveTheme(ColorThemes.LIGHT_MODE);
  //   }
  // };

  return (
    <ThemeProvider theme={themes[theme]}>
      <HashRouter>
        {/* <ColorThemeSwitch currentTheme={theme} onToggle={toggleTheme} /> */}
        <AppContent>
          <Switch>
            <Route path="/nopilot">
              <NoPilot />
            </Route>
            {stand ? (
              <Route>
                <DeviceDetector {...content} />
              </Route>
            ) : (
              <Route exact path="/">
                <RedirectOrEnter>{content.other}</RedirectOrEnter>
              </Route>
            )}
          </Switch>
        </AppContent>
        <Switch>
          <Route path={['/errors/:errorCode/:errorData', '/errors/:errorCode']}>
            <DeviceDetector other={<ErrorPage />} />
          </Route>
          <Route path="*">
            <Modal
              isOpen={modalIsOpen}
              onRequestClose={() => setIsOpen(false)}
              contentLabel="Модальное окно"
              style={getThemedPopupStyles(theme)}
              closeTimeoutMS={200}
              className="ReactModal__Content"
              overlayClassName="ReactModal__Overlay"
              parentSelector={() => document.querySelector('#modal-root') as HTMLElement}
            >
              <PopupWindow onClose={() => setIsOpen(false)}>
                {getModalContentComponent(modalContentType)}
              </PopupWindow>
            </Modal>
          </Route>
        </Switch>
      </HashRouter>
    </ThemeProvider>
  );
};
