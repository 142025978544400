import React, { useEffect } from 'react';

import { sendEvent, EventTypeEnum } from '@hrplatform/analytics';
import { env } from '@hrplatform/env';

import { ModalContentType } from '../../modals';
import { Logo } from '../Logo';

import {
  CannotLoginPopupButton,
  CertificatePopupButton,
  CitrixLoginButton,
  DesktopPrelogin,
  Header,
  LoginButton,
  TopButtonsWrapper,
  Wrapper,
} from './styled';

export interface ILoginContentProps {
  onPopupTrigger: (type: ModalContentType) => void;
}

export const LoginContent: React.FC<ILoginContentProps> = ({ onPopupTrigger }) => {
  const { stand } = env();

  useEffect(() => {
    sendEvent(EventTypeEnum.OPEN, {
      application: 'coreui',
      element: 'login_web',
      location: 'coreui-login_web',
    });
  }, []);

  return (
    <DesktopPrelogin>
      {stand && (
        <TopButtonsWrapper>
          <CertificatePopupButton
            data-analytics={JSON.stringify({
              event: EventTypeEnum.CLICK,
              application: 'coreui',
              element: 'certificate',
              location: 'coreui-login_web',
            })}
            onClick={() => onPopupTrigger(ModalContentType.CERTIFICATE)}
          >
            сертификаты
          </CertificatePopupButton>
          <CitrixLoginButton
            data-analytics={JSON.stringify({
              event: EventTypeEnum.OPEN,
              application: 'coreui',
              element: 'login_web_citrix',
              location: 'coreui-login_web',
            })}
            target="_blank"
            href="https://s-gate.sigma.sbrf.ru"
          >
            вход через Citrix
          </CitrixLoginButton>
        </TopButtonsWrapper>
      )}
      <Wrapper>
        <Header>
          <Logo />
        </Header>
        <LoginButton
          data-analytics={JSON.stringify({
            event: EventTypeEnum.CLICK,
            application: 'coreui',
            element: 'enter',
            location: 'coreui-login_web',
          })}
          href="/logout?redirect_url=/platform/"
        >
          войти
        </LoginButton>
        {stand && (
          <CannotLoginPopupButton
            data-analytics={JSON.stringify({
              event: EventTypeEnum.CLICK,
              application: 'coreui',
              element: 'cant_enter',
              location: 'coreui-login_web',
            })}
            onClick={() => onPopupTrigger(ModalContentType.CANNOT_LOGIN)}
          >
            не могу войти
          </CannotLoginPopupButton>
        )}
      </Wrapper>
    </DesktopPrelogin>
  );
};
