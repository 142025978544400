import React from 'react';

import {
  Path,
  PathItem,
  StepHeader,
  StepImage,
  TextInstruction,
  TextStyled,
  TitleStyled,
  ImageStepWrapper,
} from '../CommonMobileSteps/styled';

import FirstImg from './steps/Android_FirstImg.png';
import FourthImg from './steps/Android_FourthImg.png';
import SecondImg from './steps/Android_SecondImg.png';
import ThirdImg from './steps/Android_ThirdImg.png';

export const AndroidContent: React.FC = () => {
  return (
    <Path>
      <PathItem>
        <TitleStyled>Пульс всегда под рукой</TitleStyled>
        <TextStyled>
          Для быстрого доступа вы можете установить веб-приложение на домашний экран.
        </TextStyled>
        <StepHeader>1. Войдите в Пульс и откройте главную страницу.</StepHeader>
        <StepHeader>2. Нажмите на кнопку «Опции».</StepHeader>
        <ImageStepWrapper>
          <StepImage src={FirstImg} />
        </ImageStepWrapper>

        <StepHeader>3. Выберите пункт «Добавить на главный экран».</StepHeader>
        <ImageStepWrapper>
          <StepImage src={SecondImg} />
        </ImageStepWrapper>
        <StepHeader>4. Нажмите «Установить».</StepHeader>
        <ImageStepWrapper>
          <StepImage src={ThirdImg} />
        </ImageStepWrapper>
        <TextInstruction>Готово! Иконка веб-приложения «Пульс» появится на экране.</TextInstruction>
        <ImageStepWrapper>
          <StepImage src={FourthImg} />
        </ImageStepWrapper>
      </PathItem>
    </Path>
  );
};
