import React from 'react';
import styled from 'styled-components';

const Header = styled.h2`
  margin-bottom: 10px;
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: 600;
  font-size: 28px;
  line-height: 32px;
  letter-spacing: -0.01px;
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
`;

const TextBlock = styled.p`
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.018px;
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
`;

const OrdinaryTextBlock = styled(TextBlock)`
  margin-bottom: 10px;
  max-width: 500px;
`;

const BrowserLink = styled.a`
  color: ${({ theme }) => theme.link};
  text-decoration: none;
`;

export const CannotLoginInstruction: React.FC = () => (
  <div>
    <Header>Не&nbsp;получается войти?</Header>
    <OrdinaryTextBlock>Причин может быть несколько.</OrdinaryTextBlock>
    <OrdinaryTextBlock>
      1. Не установлен сертификат&nbsp;Sigma. Его&nbsp;название совпадает с вашим&nbsp;логином и
      обычно состоит из цифр. Чтобы установить сертификат, перейдите в раздел
      &laquo;Сертификаты&raquo; на&nbsp;главной странице Пульса.
    </OrdinaryTextBlock>
    <OrdinaryTextBlock>
      2. Версия браузера устарела. Обновите браузер, установленный на вашем&nbsp;компьютере, или
      скачайте
      <br />и используйте{' '}
      <BrowserLink href="https://sberusersoft.sigma.sbrf.ru/#program/s/408">
        СберБраузер
      </BrowserLink>
      .
    </OrdinaryTextBlock>
    <OrdinaryTextBlock>
      Если войти всё равно не удаётся, создайте заявку в Друге по шаблону{' '}
      <BrowserLink href="https://sberfriend.sbrf.ru/sberfriend/#/application/E46A017CF7F0CAABE053F7E9740A9CE8">
        &laquo;Вход в Пульс: вопросы и ошибки&raquo;
      </BrowserLink>
      .
    </OrdinaryTextBlock>
  </div>
);
