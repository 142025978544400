import React from 'react';

import {
  Path,
  PathItem,
  StepHeader,
  TitleStyled,
  TextStyled,
  StepImage,
  TextInstruction,
  ImageStepWrapper,
} from '../CommonMobileSteps/styled';

import FirstImg from './steps/IOS_FirstImg.png';
import SecondImg from './steps/IOS_SecondImg.png';
import ThirdImg from './steps/IOS_ThirdImg.png';

export const IosContent: React.FC = () => {
  return (
    <Path>
      <PathItem>
        <TitleStyled>Пульс всегда под рукой</TitleStyled>
        <TextStyled>
          Для быстрого доступа вы можете установить веб-приложение на домашний экран.
        </TextStyled>
        <StepHeader>1. Войдите в Пульс и откройте главную страницу.</StepHeader>
        <StepHeader>2. В меню браузера нажмите на кнопку «Поделиться».</StepHeader>
        <ImageStepWrapper>
          <StepImage src={FirstImg} />
        </ImageStepWrapper>
        <StepHeader>3. Выберите пункт «На экран «Домой».</StepHeader>
        <ImageStepWrapper>
          <StepImage src={SecondImg} />
        </ImageStepWrapper>
        <TextInstruction>Готово! Иконка веб-приложения «Пульс» появится на экране.</TextInstruction>
        <ImageStepWrapper>
          <StepImage src={ThirdImg} />
        </ImageStepWrapper>
      </PathItem>
    </Path>
  );
};
