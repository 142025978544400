import styled from 'styled-components';

import { ReactComponent as PulseLogo } from './assets/pulseLogo.svg';
import { ReactComponent as PulsePic } from './assets/pulsePic.svg';

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  padding-top: 34px;
  padding-bottom: 10px;
  align-items: center;
`;

export const VersionInfo = styled.p`
  color: #939393;
  font-family: 'SB Sans Text', SB Sans Display, Helvetica, Arial, sans-serif;
  font-size: 14px;
  letter-spacing: -0.018em;
  line-height: 16px;
  font-weight: 400;
  text-align: start;
`;

export const PulseLogoStyled = styled(PulseLogo)`
  align-self: center;
  fill: ${({ theme }) => theme.messageBackground};
  height: 55px;
  width: 55px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid #d9d9d9;
`;

export const Path = styled.ul`
  list-style: none;
  margin: 0;
`;

export const PathItem = styled.li`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const TextBlock = styled.div`
  color: ${({ theme }) => theme.text};
  font-size: 14px;
  letter-spacing: -0.018em;
  line-height: 20px;
  transition: color 200ms linear;
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
  margin-top: 12px;
  strong {
    font-weight: bold;
  }
`;

export const PathImage = styled.img`
  border-radius: 12px;
  margin-top: 16px;
`;

export const PulsePicStyled = styled(PulsePic)`
  align-self: center;
  margin-top: 16px;
  margin-bottom: 146px;
  fill: ${({ theme }) => theme.messageBackground};
  height: 161px;
  width: 203px;
`;

export const PointStyle = styled.li`
  list-style: disc;
  margin-left: 22px;
  font-family: SB Sans Text, Helvetica, Arial, sans-serif;
`;

export const InfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-left: 12px;
`;

export const GreenButton = styled.button`
  padding: 8px 16px;
  color: #ffffff;
  background-color: #21a038;
  border: none;
  border-radius: 6px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  font-family: 'SB Sans Text';
  letter-spacing: -0.022em;
  height: 32px;
`;

export const GreyBlock = styled.div`
  margin-top: 16px;
  background-color: #fafafa;
  padding: 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const InfoBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Wrapper = styled('div')({
  padding: '40px 20px',

  '@media (min-width: 600px)': {
    padding: '40px 60px',
  },
  '@media (min-width: 1280px)': {
    display: 'none',
  },
});

export const Link = styled('a')({
  textDecoration: 'none',
  color: '#0066ff',
});

export const Title = styled('div')({
  color: '#292929',
  fontFamily: 'SB Sans Display, Helvetica, Arial, sans-serif',
  fontSize: '28px',
  fontWeight: 600,
  letterSpacing: '-0.01em',
  lineHeight: '32px',
  transition: 'color 200ms linear',
  textAlign: 'center',
});

export const TitleStyled = styled('div')({
  marginBottom: '16px',
  fontSize: '24px',
  lineHeight: '28px',
  fontWeight: 600,
  fontFamily: 'SB Sans Text',
  letterSpacing: '-0.0125em',
  color: '#292929',
});

export const TextStyled = styled('div')({
  marginBottom: '40px',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 400,
  fontFamily: 'SB Sans Text',
  letterSpacing: '-0.018em',
  color: '#292929',
});

export const TextInstruction = styled('div')({
  marginBottom: '16px',
  fontSize: '16px',
  lineHeight: '20px',
  fontWeight: 400,
  fontFamily: 'SB Sans Text',
  letterSpacing: '-0.018em',
  color: '#292929',
});

export const StepImage = styled('img')({
  width: 648,
  height: 186,
});

export const StepHeader = styled('div')({
  marginBottom: '16px',
  color: '#292929',
  fontFamily: 'SB Sans Text, Helvetica, Arial, sans-serif',
  fontSize: '16px',
  fontWeight: 600,
  letterSpacing: '-0.018em',
  lineHeight: '20px',
  transition: 'color 200ms linear',
});

export const ButtonStyled = styled('a')({
  alignSelf: 'center',
  width: 'calc(100% - 60px)',
  '@media (min-width: 600px)': {
    width: 49,
    margin: '32px auto 28px',
  },
  backgroundColor: '#0066ff',
  borderRadius: '8px',
  border: '1px solid #0066ff',
  color: '#fff',
  cursor: 'pointer',
  display: 'block',
  fontWeight: 600,
  letterSpacing: '-0.018em',
  marginBottom: '28px',
  marginTop: '32px',
  padding: '14px 28px',
  textDecoration: 'none',
  textAlign: 'center',
  transition: 'background-color 200ms linear, color 200ms linear',
  fontFamily: 'SB Sans Text, Helvetica, Arial, sans-serif',
});

export const ImageWrapper = styled('img')({
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto 24px',
  width: '100%',
  maxWidth: 335,
});

export const ImageFooter = styled('img')({
  display: 'flex',
  justifyContent: 'center',
  margin: '0 auto',
  width: 250,
  height: 250,
});

export const ImageStepWrapper = styled('div')({
  overflow: 'hidden',
  height: 184,
  maxWidth: 648,
  width: '100%',
  border: '1px solid #e3e3e3',
  borderRadius: 8,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: '0 auto 40px',

  ':last-child': {
    marginBottom: '72px',
  },
});
