import typography from '@pulse/typography';
import styled from 'styled-components';

export const Wrapper = styled.div({
  maxWidth: '500px',
});

export const Header = styled.h2(({ theme }) => ({
  ...typography.h2Semibold,
  paddingBottom: '10px',
  color: theme.text,
}));

export const HeaderComment = styled.p(({ theme }) => ({
  ...typography.body1Regular,
  marginBottom: '24px',
  color: theme.text,
}));

export const InstallButton = styled.a(({ theme }) => ({
  ...typography.body1Semibold,
  display: 'inline-block',
  marginBottom: '24px',
  padding: '14px 28px',
  borderRadius: '8px',
  background: theme.button,
  color: theme.buttonText,
  textDecoration: 'none',
}));

export const TextBlock = styled.p({
  ...typography.body1Regular,
  letterSpacing: '0.018px',
  marginBottom: '10px',
  color: '#292929',
  maxWidth: '500px',
});

export const Link = styled.a(({ theme }) => ({
  color: theme.link,
  textDecoration: 'none',
}));
