import typography from '@pulse/typography';
import styled from 'styled-components';

export const Header = styled.div`
  color: ${({ theme }) => theme.text};
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 1;
  letter-spacing: -0.55px;
  transition: color 200ms linear;

  img {
    display: block;
    max-width: 70vw;
    margin: 0 auto;
  }
`;

export const CannotLoginPopupButton = styled.button(({ theme }) => ({
  ...typography.body1Regular,
  display: 'block',
  border: 'none',
  background: 'none',
  color: theme.mutedText,
  transition: 'color 200ms linear',
}));

export const CertificatePopupButton = styled.button(({ theme }) => ({
  ...typography.body1Regular,
  display: 'inline-block',
  marginRight: '40px',
  border: 'none',
  background: 'none',
  color: theme.mutedText,
  transition: 'color 200ms linear',
  padding: 0,
}));

export const CitrixLoginButton = styled.a(({ theme }) => ({
  ...typography.body1Regular,
  display: 'inline-block',
  color: theme.mutedText,
  transition: 'color 200ms linear',
  textDecoration: 'none',
  marginRight: '40px',
}));

export const TopButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
  align-items: baseline;
`;

export const LoginButton = styled.a(({ theme }) => ({
  ...typography.h4Semibold,
  display: 'block',
  padding: '13px 32px',
  borderRadius: '8px',
  marginTop: '20px',
  marginBottom: '32px',
  background: theme.button,
  color: theme.buttonText,
  textDecoration: 'none',
  transition: 'background-color 200ms linear, color 200ms linear',
}));

export const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - 78px);
`;

export const DesktopPrelogin = styled('div')({
  '@media (max-width: 1280px)': {
    display: 'none',
  },
});
