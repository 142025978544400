import React from 'react';

import { Wrapper, Header, HeaderComment, InstallButton, TextBlock, Link } from './styled';

export const CertificateInstruction: React.FC = () => (
  <Wrapper>
    <Header>установите сертификаты безопасности</Header>
    <HeaderComment>Они нужны для доступа к&nbsp;сервисам Сбербанка.</HeaderComment>
    <InstallButton href="https://pking.sberbank.ru/">перейти к&nbsp;установке</InstallButton>
    <TextBlock>
      Вы&nbsp;перейдёте в&nbsp;другой сервис, откуда сможете скачать и&nbsp;установить
      на&nbsp;компьютер три электронных сертификата безопасности.
    </TextBlock>
    <TextBlock>
      Для этого понадобится ввести логин и&nbsp;пароль от вашей учётной записи Sigma. У большинства
      сотрудников логин состоит из&nbsp;цифр.
    </TextBlock>
    <TextBlock>
      Если установить сертификаты не получается, создайте&nbsp;заявку в Друге по шаблону{' '}
      <Link href="https://sberfriend.sbrf.ru/sberfriend/#/application/E46A017CF7F0CAABE053F7E9740A9CE8 ">
        &laquo;Вход в Пульс: вопросы и ошибки&raquo;
      </Link>
      .
    </TextBlock>
  </Wrapper>
);
