import React from 'react';

import {
  ButtonStyled,
  ImageWrapper,
  Link,
  TextBlock,
  TextStyled,
  Title,
  Wrapper,
  ImageFooter,
} from '../CommonMobileSteps/styled';

import GirlWithCool from './images/GirlWithCool.png';
import WelcomePageImg from './images/WelcomePageImg.png';

export const WelcomePulse: React.FC = ({ children }) => {
  return (
    <Wrapper>
      <ImageWrapper src={WelcomePageImg} />
      <Title>Добро пожаловать в Пульс!</Title>
      <ButtonStyled href="/logout?redirect_url=/platform/">войти</ButtonStyled>
      <TextBlock>
        <TextStyled>
          Перед входом убедитесь, что на вашем устройстве установлены{' '}
          <Link href="https://www.gosuslugi.ru/crt" target="_blank">
            сертификаты Минцифры
          </Link>
          , а также{' '}
          <Link href="https://pking.sberbank.ru/" target="_blank">
            сертификаты Сбера
          </Link>{' '}
          — они нужны для доступа к сервисам банка.
        </TextStyled>
      </TextBlock>
      {children}
      <TextStyled>
        Если с доступом возникли сложности, вы можете{' '}
        <Link
          href="https://sberfriend.sbrf.ru/sberfriend/#/application/E46A017CF7F0CAABE053F7E9740A9CE8"
          target="_blank"
        >
          создать заявку в Друге
        </Link>
        .
      </TextStyled>
      <ImageFooter src={GirlWithCool} />
    </Wrapper>
  );
};
